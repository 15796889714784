@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.loader {
  width: 10rem;
  height: 10rem;
  display: inline-block;
  border: 3px solid rgba(23, 23, 23, 0.4);
  border-top-color: #171717;
  border-radius: 50%;
  animation: 1s spin infinite ease-in-out;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes clicked {
  50% {
    background-color: #4caf50;
  }
  100% {
    background-color: #171717;
  }
}

.cta[clicked="1"] {
  animation: clicked 1s 1;
}
